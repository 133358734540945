import { useState, useEffect, useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { useNavigate } from "react-router-dom";
import {
  cleanSessionCookie,
  getSessionCookie,
  SessionContext,
} from "../helpers/session";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import useMobile from "../hooks/useOrientation";
import { useDispatch } from "react-redux";
import { cleanPassengers } from "../store/actions/passengerActions";

library.add(faArrowRightFromBracket);
export default function MenuAppBar() {
  const headerItems = [
    {
      key: 0,
      label: "Perfil",
      path: "/perfil",
    },
    {
      key: 1,
      label: "Ingresar pago",
      path: "/pagar",
    },
    {
      key: 2,
      label: "Mis viajes",
      path: "/misViajes",
    },
    {
      key: 3,
      label: "Contacto",
      path: "/contacto",
    },
    // {
    //   key: 4,
    //   label: "CREA TU HABITACIÓN!",
    //   path: "/habitaciones",
    // },
  ];
  const [selectedItem, setSelectedItem] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [userName, setUserName] = useState();
  // const [roomPending, setRoomPending] = useState();
  const { user, logout } = useContext(SessionContext);

  const isMobile = useMobile();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    setUserName(getSessionCookie());
  }, []);

  const handleMenu = (event) => {
    setOpenMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleLogout = () => {
    cleanSessionCookie();
    dispatch(cleanPassengers());
    logout(); // Clear session and user value
    navigate("/login");
  };

  const onChangeItem = (key, path) => {
    setSelectedItem(key);
    setOpenMenu(false);
    navigate(path);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <AppBar position="static">
        <Toolbar>
          <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={handleMenu}
            >
              <MenuIcon />
            </IconButton>
            {!isMobile &&
              headerItems.map((hi, index) => {
                return (
                  <MenuItem key={index}>
                    <Typography onClick={() => onChangeItem(hi.key, hi.path)}>
                      <span
                        style={{
                          fontWeight: selectedItem === hi.key ? "800" : "300",
                        }}
                      >
                        {hi.label}
                      </span>
                    </Typography>
                  </MenuItem>
                );
              })}
            {/* {roomPending ? (
              <MenuItem>
                <Typography
                  onClick={() =>
                    navigate("/habitaciones", {
                      passenger: roomPending,
                    })
                  }
                >
                  <span
                    style={{
                      fontWeight: "300",
                    }}
                  >
                    CREAR HABITACION
                  </span>
                </Typography>
              </MenuItem>
            ) : null} */}
          </div>
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleLogout}
              color="inherit"
            >
              <span style={{ fontSize: 18 }}>Salir</span>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={openMenu}
              onClose={handleClose}
            >
              {headerItems.map((hi, index) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => onChangeItem(hi.key, hi.path)}
                  >
                    <span
                      style={{
                        fontWeight: selectedItem === hi.key ? "800" : "300",
                      }}
                    >
                      {hi.label}
                    </span>
                  </MenuItem>
                );
              })}
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
