import { createContext, useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { useDispatch } from "react-redux";
import { getPassengers } from "../services/passengerService";
import { getGroupByTripId } from "../services/groupService";
import { addPassenger } from "../store/actions/passengerActions";
import { processErrorResponse } from "./processErrorResponse";
// import { getUserTrips } from "../services/userService";

export const setSessionCookie = (value, user) => {
  secureLocalStorage.setItem("user", JSON.stringify(user));
  secureLocalStorage.setItem("user_session", value);
};

export const getSessionCookie = () => {
  return secureLocalStorage.getItem("user_session");
};

export const cleanSessionCookie = () => {
  secureLocalStorage.removeItem("user_session");
  secureLocalStorage.removeItem("user");
  secureLocalStorage.clear();
};

export const SessionContext = createContext(getSessionCookie());

export const SessionProvider = ({ children }) => {
  const [user, setUser] = useState(
    JSON.parse(secureLocalStorage.getItem("user"))
  );

  // useEffect(() => {
  //   if (user) {
  //     getPassengers(user.id).then((res) => {
  //       setUser({ ...user, passengers: res });
  //     });
  //   }
  // }, []);

  const logout = () => {
    cleanSessionCookie();
    setUser(null); // Set user to null on logout
  };

  return (
    <SessionContext.Provider value={{ user, setUser, logout }}>
      {children}
    </SessionContext.Provider>
  );
};
